@import "./font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --white: 0deg 0% 100%;
    --black: 0deg 0% 0%;
    --background: 240deg 42% 17%;
    --background-light: 243deg 40% 22%;
    --background-extra-light: 243deg 32% 27%;
    --foreground: 0deg 0% 100%;
    --primary: 257deg 100% 76%;
    --primary-bg-hover: 255deg 100% 82%;
    --primary-bg-disabled: 250deg 35% 35%;
    --secondary: 175deg 65% 53%;
    --success: 142deg 76% 36%;
    --warning: 36deg 100% 57%;
    --error: 0deg 84% 60%;
    --pending: 41deg 96% 40%;
    --gray: 251deg 15% 78%;
    --notification: 246deg 59% 10%;
    --menu: 246deg 59% 10%;
    --onboarding-accent: 175deg 65% 53%;
    --shadow: 240deg 42% 10%;
  }

  body {
    @apply bg-background font-sans text-base text-foreground;
  }

  .bg-app {
    background-image: url("./images/bg_app_bottom.png"); /* TODO: Get an image with a transparent background */
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto 500px;
  }

  .bg-homepage {
    background-image: url("./images/bg_homepage_top.png"); /* TODO: Get an image with a transparent background */
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto 500px;
  }

  .bg-mission-default {
    background-image: url("./images/bg_mission_default.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-mission-onboarding {
    background-image: url("./images/bg_mission_onboarding.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-xp-badge-default {
    background-image: url("./images/bg_xp_points_badge_default.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-xp-badge-onboarding {
    background-image: url("./images/bg_xp_points_badge_onboarding.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
